import abstractComponent from "../abstractComponent.js";
const componentAttribute = "data-pullout-menu";

class header extends abstractComponent
{

    constructor(){
        super();
        this.componentAttribute = componentAttribute;
        this.runComponent(this);
    }

    init(componentElement) {
        this.componentElement = componentElement;
        this.setupClickHandlers();
    }

    setupClickHandlers() {
        let showButtons = document.querySelectorAll("[data-pullout-menu-open]");

        showButtons.forEach(button => {
            button.onclick = function(){
                this.menuOpen()
            }.bind(this);
        });

        let hideButtons = document.querySelectorAll("[data-pullout-menu-close]");

        hideButtons.forEach(button => {
            button.onclick = function(){
                this.menuClose();
            }.bind(this);
        });

    }

    menuOpen(){
        let distanceMove = $(window).width() - 50;
        var body = document.getElementsByTagName("body")[0];

        $("[data-pullout-menu-content]")
            .css("transform", `translateX(-${distanceMove}px)`);

        $("[data-pullout-menu-open]").toggleClass("d-none");

        body.style.overflowY = ( body.style.overflowY != "hidden")? "hidden" : "scroll"
    }

    menuClose(){
        var body = document.getElementsByTagName("body")[0];
        body.style.overflowY = ( body.style.overflowY != "hidden")? "hidden" : "scroll";

        $("[data-pullout-menu-content]")
            .css("transform", `translateX(0px)`);

        $("[data-pullout-menu-open]").toggleClass("d-none");
    }


}

export default header;
