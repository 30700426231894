class abstractComponent {

    runComponent(component) {
        let elements = document.querySelectorAll(`[${component.componentAttribute}]`);
        elements.forEach(componentElement => {
            component.init(componentElement)
        });
    }
}

export default abstractComponent;