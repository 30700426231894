import abstractComponent from "../abstractComponent.js";
const componentAttribute = "data-gallery";

class gallery extends abstractComponent
{

    constructor(){
        super();
        this.componentAttribute = componentAttribute;
        this.runComponent(this);
    }

    init(componentElement){
        this.componentElement = componentElement;
        this.setupClickHandler();
        this.setImageCount();

    }

    setImageCount() {
        let count =  this.componentElement.querySelectorAll("[data-gallery-image]").length;
        this.imageCount = count;
    }

    setupClickHandler() {
        this
            .componentElement
            .querySelectorAll("[data-gallery-image]")
            .forEach(image => {
                image.onclick = () => {
                    this.openImageModal(image);
                    this.showNavigation(image.getAttribute("data-gallery-image-key"));
                }
            });

        document
            .querySelector("[data-gallery-modal-bg]").onclick = () => {
            this.closeImageModal()
        }

        document
            .querySelector("[data-gallery-modal-prev]").onclick = () => {
            this.previousImage();
        }

        document
            .querySelector("[data-gallery-modal-next]").onclick = () => {
            this.nextImage();
        }

        document.querySelector("[data-gallery-modal-close]").onclick = () => {
            this.closeImageModal()
        }
    }

    previousImage() {
        let imageToGet =  this.activeImage - 1;
        this.setActiveImage(imageToGet);
        this.showNavigation(imageToGet);
        let image = this.componentElement.querySelector(`[data-gallery-image-key="${imageToGet}"]`);
        this.changeModalImage(image.getAttribute("data-gallery-image"));
    }

    nextImage() {
        let imageToGet =  this.activeImage + 1;
        this.setActiveImage(imageToGet);
        this.showNavigation(imageToGet);
        let image = this.componentElement.querySelector(`[data-gallery-image-key="${imageToGet}"]`);
        this.changeModalImage(image.getAttribute("data-gallery-image"));
    }

    showNavigation(key) {
        key = parseInt(key);
        if(key == 0){
            document.querySelector("[data-gallery-modal-prev]").classList.add("gallery-modal__navigation--hide");
        }
        else {
            document.querySelector("[data-gallery-modal-prev]").classList.remove("gallery-modal__navigation--hide");
        }

        if((key + 1) == this.imageCount){
            document.querySelector("[data-gallery-modal-next]").classList.add("gallery-modal__navigation--hide");
        }
        else {
            document.querySelector("[data-gallery-modal-next]").classList.remove("gallery-modal__navigation--hide");
        }
    }

    setActiveImage(key) {
        this.activeImage = parseInt(key);
    }

    changeModalImage(src) {
        let modal = document
            .querySelector("[data-gallery-modal]");

        modal
            .querySelector("[data-gallery-modal-image]")
            .setAttribute("src", src);
    }

    openImageModal(image){
        this.setActiveImage(image.getAttribute("data-gallery-image-key"));

        let modal = document
            .querySelector("[data-gallery-modal]");

        document.getElementsByTagName("body")[0].style.overflow = "hidden";

        modal
            .querySelector("[data-gallery-modal-image]")
            .setAttribute("src", image.getAttribute("data-gallery-image"));

        modal
            .classList
            .add("gallery-modal--open");
    }

    closeImageModal(){
        document.getElementsByTagName("body")[0].style.overflow = "unset";

        document
            .querySelector("[data-gallery-modal]")
            .classList
            .remove("gallery-modal--open")

    }
}

export default gallery;
